import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Section from 'components/common/Section';
import PropTypes from 'prop-types';

const ResultHeading = ({ formulas, showLeadForm }) => {
  return (
    <Section className="pt-4 pt-lg-6 pb-0" id="header-simulator">
      {formulas && !showLeadForm && (
        <>
          <h1>
            Mon estimation{' '}
            <span className="highlight-yellow">personnalisée</span>
          </h1>
          <Row className="align-items-center justify-content-center mt-3">
            <Col xl={12} lg={12}>
              <p className="mb-0">
                Félicitations, vous venez d’obtenir votre estimation Qwenty
                personnalisée ! Vous pouvez ainsi disposer d’une fourchette de
                prix, même si cette dernière ne constitue pas un devis officiel.
                Pour en obtenir un, n’hésitez pas à nous contacter !
              </p>
            </Col>
          </Row>
        </>
      )}
    </Section>
  );
};

ResultHeading.propTypes = {
  formulas: PropTypes.array,
  showLeadForm: PropTypes.any
};

export default ResultHeading;
