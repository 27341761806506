import React from 'react';
import { Row, Col, ProgressBar, Button } from 'react-bootstrap';
import className from 'classnames';
import Section from 'components/common/Section';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { PutStatisticSimulator } from '../../services/SimulatorService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeadForm from './LeadForm';
import homeQwenty from 'assets/img/home-qwenty.webp';

const Services = ({
  simulator,
  question,
  setQuestion,
  questionsHistory,
  setQuestionsHistory,
  progress,
  setProgress,
  setFormulas,
  statisticId,
  questionsHistoryStat,
  setQuestionsHistoryStat,
  showLeadForm,
  setShowLeadForm
}) => {
  const switchQuestion = answer => {
    if (answer.children_question_id) {
      const filteredQuestion = simulator.filter(obj => {
        return obj.id === answer.children_question_id;
      });
      const checkIfFromFirstQuestion = simulator.filter(obj => {
        return (
          obj.id === answer.parent_question_id && obj.simulator_entry === true
        );
      });
      if (filteredQuestion[0]) {
        setQuestion(filteredQuestion[0]);
        let questionsHistoryTmp = [...questionsHistory];
        questionsHistoryTmp.push(filteredQuestion[0]);
        setQuestionsHistory(questionsHistoryTmp);

        let questionsHistoryStatTmp = [...questionsHistoryStat];
        if (checkIfFromFirstQuestion[0]) {
          questionsHistoryStatTmp.push(checkIfFromFirstQuestion[0]);
        }
        questionsHistoryStatTmp.push(answer);
        questionsHistoryStatTmp.push(filteredQuestion[0]);
        setQuestionsHistoryStat(questionsHistoryStatTmp);
        setProgressBar(filteredQuestion[0]);
        if (statisticId) {
          PutStatisticSimulator(1, statisticId, {
            history: questionsHistoryStatTmp
          });
        }
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    } else if (answer.formulas_public && answer.formulas_public.length > 0) {
      if (!localStorage.getItem('qwenty.lead')) {
        setShowLeadForm(true);
        setProgress(98);
      }
      setFormulas(answer.formulas_public);
      let questionsHistoryStatTmp = [...questionsHistoryStat];
      questionsHistoryStatTmp.push(answer);
      if (statisticId) {
        PutStatisticSimulator(1, statisticId, {
          history: questionsHistoryStatTmp
        });
        setQuestionsHistoryStat(questionsHistoryStatTmp);
      }
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };
  const backQuestion = question => {
    const filteredQuestion = simulator.filter(obj => {
      return obj.id === question.id;
    });
    if (filteredQuestion[0]) {
      setQuestion(filteredQuestion[0]);
      let questionsHistoryTmp = questionsHistory;
      let questionsHistoryStatTmp = [...questionsHistoryStat];
      questionsHistoryTmp.splice(-1, 1);
      questionsHistoryStatTmp.splice(-2, 2);
      setQuestionsHistory(questionsHistoryTmp);
      setQuestionsHistoryStat(questionsHistoryStatTmp);
      if (statisticId) {
        PutStatisticSimulator(1, statisticId, {
          history: questionsHistoryStatTmp
        });
      }
      setProgressBar(filteredQuestion[0]);
      setShowLeadForm(false);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };
  const setProgressBar = question => {
    if (question.simulator_entry === true) {
      setProgress(10);
    } else {
      let depth = maxDepth(question);
      let progressCalculation =
        (questionsHistory.length * 100) / (depth + questionsHistory.length);
      setProgress(progressCalculation);
    }
  };
  const maxDepth = function (root) {
    if (root === null || root === undefined) return 0;
    let depths = [0];
    root.answeres.map(answer => {
      let concernedQuestion = simulator.filter(obj => {
        return obj.id === answer.children_question_id;
      });
      if (concernedQuestion[0]) {
        depths.push(maxDepth(concernedQuestion[0]));
      }
    });
    return depths.length;
  };
  return (
    question && (
      <>
        {showLeadForm !== true &&
          questionsHistory &&
          questionsHistory.length > 1 && (
            <Section className="pt-0 section-simulator pb-0">
              <Row className="mt-lg-5 mt-3 ps-0">
                <Col lg={12} className="text-start">
                  <span
                    className="back-question cursor-pointer"
                    onClick={() =>
                      backQuestion(
                        questionsHistory[questionsHistory.length - 2]
                      )
                    }
                  >
                    <small>
                      <FontAwesomeIcon icon="arrow-left"></FontAwesomeIcon>{' '}
                      Question précédente
                    </small>
                  </span>
                </Col>
              </Row>
            </Section>
          )}
        {showLeadForm === true ? (
          <Section className="pt-0 section-simulator">
            <h1 className="">
              Plus qu'une étape pour accéder à mon{' '}
              <span className="highlight-yellow">estimation</span>
            </h1>
            <p className="pb-lg-5 pt-2 pt-lg-0">
              Vous n'êtes plus qu'à un clic d'obtenir votre estimation Qwenty
              pour votre projet web ! Les données que nous recueillons ici sont
              strictement conservées en interne et protégées, et ne servent qu’à
              vous contacter pour discuter, si vous le souhaitez !
            </p>
            <LeadForm
              questionsHistoryStat={questionsHistoryStat}
              showLeadForm={showLeadForm}
              setShowLeadForm={setShowLeadForm}
            />
          </Section>
        ) : (
          <Section className="pt-0 pt-lg-4 section-simulator">
            <Row>
              <Col lg={8}>
                <h2 className="mt-3 mt-md-4 mt-lg-0 mb-0 question-title">
                  {question.name}
                </h2>
                {question.answeres.map((answer, index) => (
                  <Row className="mt-3 align-items-center" key={index}>
                    <Col xs={12} className={className({ 'mt-0': index > 0 })}>
                      <Button
                        size="sm"
                        variant="falcon-default"
                        className="me-2"
                        onClick={() => switchQuestion(answer)}
                      >
                        {answer.name} ->
                      </Button>
                    </Col>
                  </Row>
                ))}
                <div className="progress-container mt-4 mt-lg-5">
                  <Col xs={10} lg={6} className="col-progress pb-5">
                    <p className="mb-1">
                      <small>Progression du questionnaire</small>
                    </p>
                    <ProgressBar now={progress} />
                  </Col>
                </div>
              </Col>
              <Col lg={4} className="d-none d-lg-block">
                <img
                  className="w-100 pt-0"
                  src={homeQwenty}
                  alt="Estimation prix site internet Qwenty"
                />
              </Col>
            </Row>
          </Section>
        )}
      </>
    )
  );
};

Services.propTypes = {
  simulator: PropTypes.any,
  question: PropTypes.any,
  setQuestion: PropTypes.any,
  questionsHistory: PropTypes.array,
  setQuestionsHistory: PropTypes.any,
  progress: PropTypes.number,
  setProgress: PropTypes.any,
  setFormulas: PropTypes.any,
  statisticId: PropTypes.any,
  questionsHistoryStat: PropTypes.any,
  setQuestionsHistoryStat: PropTypes.func,
  showLeadForm: PropTypes.any,
  setShowLeadForm: PropTypes.func
};

export default Services;
