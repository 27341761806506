import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Section from 'components/common/Section';
import PropTypes from 'prop-types';

const SimulatorHeading = () => {
  return (
    <Section className="pt-4 pt-md-5 pt-lg-6 pb-0" id="header-simulator">
      <h1>
        <span className="highlight-yellow">Estimez votre projet</span> avec le
        calculateur Qwenty
      </h1>
      <Row className="align-items-center justify-content-center mt-3">
        <Col xl={12} lg={12}>
          <p className="mb-0">
            Vous avez un projet web pour votre entreprise ? Vous vous demandez
            combien cela peut bien coûter ? Ne cherchez pas plus loin, vous êtes
            tombé sur l’estimateur le plus complet du marché !
          </p>
        </Col>
      </Row>
    </Section>
  );
};

SimulatorHeading.propTypes = {
  formulas: PropTypes.array,
  showLeadForm: PropTypes.any
};

export default SimulatorHeading;
