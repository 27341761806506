import { Button, Col, Form, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { PostContact } from '../../services/ContactService';
import { toast } from 'react-toastify';
import seo from 'assets/img/seo-276-5-1.webp';
import FormAlert from './global/FormAlert';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import PropTypes from 'prop-types';

const LeadForm = ({ questionsHistoryStat, setShowLeadForm }) => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    company: '',
    email: '',
    phone: '',
    rgpd: 0,
    questionsHistoryStat: questionsHistoryStat,
    'g-recaptcha-response': false
  });
  const [errorForm, setErrorForm] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const ReComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmit = async () => {
      try {
        setButtonIsLoading(true);
        const token = await executeRecaptcha('check_contact_form_mnspf');
        let formDataSubmit = {
          ...formData,
          'g-recaptcha-response': token
        };
        const response = await PostContact(formDataSubmit);
        if (response.validator) {
          setErrorForm(response.validator);
          document.getElementById('formcontact').scrollIntoView();
        }
        setButtonIsLoading(false);
        if (response.success === true) {
          localStorage.setItem('qwenty.lead', JSON.stringify(formData));
          setShowLeadForm(false);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        } else if (!response.validator) {
          toast('Une erreur est survenue, veuillez réessayer plus tard');
        }
      } catch (err) {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    return (
      <div>
        <GoogleReCaptcha />
        <Button
          size="sm"
          variant="falcon-default blue lead-submit"
          className="me-2 mt-3 mt-lg-1"
          style={{ borderWidth: '2px' }}
          disabled={buttonIsLoading}
          onClick={handleSubmit}
        >
          Accéder à mon estimation ->
        </Button>
      </div>
    );
  };

  const handleFieldChange = e => {
    if (e.target.name === 'rgpd') {
      setFormData({
        ...formData,
        [e.target.name]: formData.rgpd === 0 ? 1 : 0
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  return (
    <Row className="">
      <Col lg={6} className="text-start">
        <Row>
          <Col lg={12} className="text-start mt-4 mt-lg-0" id="formcontact">
            <>
              {errorForm && <FormAlert variant="danger" data={errorForm} />}
              <Form>
                <Row className="mb-3 mb-lg-4 g-3">
                  <Form.Group
                    as={Col}
                    controlId="formGridfirstname"
                    className="mt-0"
                  >
                    <Form.Label>
                      Prénom <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Votre prénom"
                      name="firstname"
                      value={formData.firstname}
                      onChange={handleFieldChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    className="mt-0"
                    as={Col}
                    controlId="formGridlastname"
                  >
                    <Form.Label>
                      Nom <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Votre nom"
                      name="lastname"
                      value={formData.lastname}
                      onChange={handleFieldChange}
                      required
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-3 mb-lg-4 g-3">
                  <Form.Group as={Col} controlId="formGridoccupation">
                    <Form.Label>
                      Nom de votre entreprise{' '}
                      <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Votre entreprise"
                      name="company"
                      value={formData.company}
                      onChange={handleFieldChange}
                      required
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-3 mb-lg-4 g-3">
                  <Form.Group
                    as={Col}
                    style={{ zIndex: 9 }}
                    controlId="formGridemail"
                  >
                    <Form.Label>
                      E-mail <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="mail"
                      placeholder="Votre adresse email"
                      name="email"
                      value={formData.email}
                      onChange={handleFieldChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridphone">
                    <Form.Label>
                      <span className="d-none d-lg-block">
                        Votre numéro de téléphone
                      </span>
                      <span className="d-block d-lg-none">Téléphone</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Votre numéro de téléphone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleFieldChange}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-0 mb-lg-1 g-3">
                  <Form.Group as={Col} className="mb-0" id="formGridrgpd">
                    <Form.Check
                      type="checkbox"
                      name="rgpd"
                      className="check-rgpd"
                      checked={formData.rgpd === 1}
                      onChange={handleFieldChange}
                      label={
                        <small
                          style={{ lineHeight: 'normal', display: 'block' }}
                        >
                          En validant ce formulaire, vous acceptez que les
                          informations communiquées soient utilisées afin de
                          traiter correctement votre demande et conformément à
                          la{' '}
                          <a
                            href="https://qwenty.fr/politique-de-confidentialite"
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              color: '#00394d',
                              textDecoration: 'underline'
                            }}
                          >
                            politique de confidentialité
                          </a>
                          .
                        </small>
                      }
                    />
                  </Form.Group>
                </Row>
                <GoogleReCaptchaProvider
                  reCaptchaKey="6LeCxtoZAAAAAIj8jlShvSR2896YA1mU3TSE4P2Y"
                  language="fr-FR"
                >
                  <ReComponent />
                </GoogleReCaptchaProvider>
              </Form>
            </>
          </Col>
        </Row>
      </Col>
      <Col lg={6} className="text-center">
        <img
          className="w-100 w-md-75 pt-5 pt-md-7 pt-lg-4"
          src={seo}
          alt="Estimation prix site internet Qwenty"
        />
      </Col>
    </Row>
  );
};

LeadForm.propTypes = {
  questionsHistoryStat: PropTypes.array,
  showLeadForm: PropTypes.any,
  setShowLeadForm: PropTypes.func
};

export default LeadForm;
