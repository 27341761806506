import React, { useEffect, useState } from 'react';
import NavbarStandard from './NavbarStandard';
import Services from './Services';
import {
  IndexAnswereSimulator,
  PostStatisticSimulator
} from '../../services/SimulatorService';
import { toast } from 'react-toastify';
import Formulas from './Formulas';
import SimulatorHeading from './global/SimulatorHeading';
import loading from 'assets/img/animated-icons/loading.json';
import { Col, Row } from 'react-bootstrap';
import Lottie from 'lottie-react';
import ResultHeading from './global/ResultHeading';

const Landing = () => {
  const [simulator, setSimulator] = useState(false);
  const [procedureCategories] = useState([]);
  const [question, setQuestion] = useState(false);
  const [questionsHistory, setQuestionsHistory] = useState([]);
  const [questionsHistoryStat, setQuestionsHistoryStat] = useState([]);
  const [formulas, setFormulas] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(false);
  const [selectedExample, setSelectedExample] = useState(false);
  const [selectedFormula, setSelectedFormula] = useState(false);
  const [statisticId, setStatisticId] = useState(false);
  const [progress, setProgress] = useState(10);
  const [trigger] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showLeadForm, setShowLeadForm] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const responseSimulator = await IndexAnswereSimulator('offers');
      if (responseSimulator.success === true) {
        setSimulator(responseSimulator.data);
        const entryFirstFiltered = responseSimulator.data.filter(obj => {
          return obj.simulator_entry === true;
        });
        setQuestion(entryFirstFiltered[0]);
        setQuestionsHistory([entryFirstFiltered[0]]);
        setIsLoading(false);
        const responseStatistics = await PostStatisticSimulator(1, {});
        if (responseStatistics.success === true) {
          setStatisticId(responseStatistics.data.id);
        }
      } else {
        setIsLoading(true);
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchData();
  }, [trigger]);

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      <NavbarStandard />
      {formulas && formulas.length > 0 && (
        <ResultHeading formulas={formulas} showLeadForm={showLeadForm} />
      )}
      {question.simulator_entry === true && (
        <SimulatorHeading formulas={formulas} showLeadForm={showLeadForm} />
      )}
      {isLoading ? (
        <>
          <Row className="justify-content-center">
            <Col>
              <Lottie options={loadingOptions} width="220px" height="220px" />
            </Col>
          </Row>
        </>
      ) : (
        <>
          {formulas && !showLeadForm ? (
            <Formulas
              simulator={simulator}
              setQuestion={setQuestion}
              formulas={formulas}
              setFormulas={setFormulas}
              setQuestionsHistory={setQuestionsHistory}
              setProgress={setProgress}
              procedureCategories={procedureCategories}
              questionsHistoryStat={questionsHistoryStat}
              setQuestionsHistoryStat={setQuestionsHistoryStat}
              statisticId={statisticId}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              selectedFormula={selectedFormula}
              setSelectedFormula={setSelectedFormula}
              selectedExample={selectedExample}
              setSelectedExample={setSelectedExample}
            />
          ) : (
            <Services
              simulator={simulator}
              question={question}
              setQuestion={setQuestion}
              questionsHistory={questionsHistory}
              setQuestionsHistory={setQuestionsHistory}
              progress={progress}
              setProgress={setProgress}
              setFormulas={setFormulas}
              statisticId={statisticId}
              questionsHistoryStat={questionsHistoryStat}
              setQuestionsHistoryStat={setQuestionsHistoryStat}
              showLeadForm={showLeadForm}
              setShowLeadForm={setShowLeadForm}
            />
          )}
        </>
      )}
    </>
  );
};

export default Landing;
